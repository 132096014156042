import React from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

const CTASection = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/services');
    window.scrollTo(0, 0); // Reset scroll position to the top of the page
  };

    return (
        <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="text-center my-12"
            >
            <h3 className="text-2xl font-bold text-white mb-4">View Our Services</h3>
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={handleNavigate}
                className="bg-blue-500 text-white font-bold py-2 px-4 rounded cursor-pointer"
            >
                Explore
            </motion.button>
        </motion.div>
    );
};

export default CTASection;
