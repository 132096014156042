import React from 'react';
import { motion } from 'framer-motion';
import AboutUs from './AboutUs';

const HeroSection = () => {
  return (
    <div className="bg-gray-800 text-white text-center p-8">
      <img src={'logo-alpha-ai.png'} alt="Joramax Ltd Logo" className="mx-auto mb-2 w-64 h-auto" />
      <motion.h1
        initial={{ y: -250 }}
        animate={{ y: -10 }}
        transition={{ type: 'spring', stiffness: 70 }}
        className="text-4xl md:text-6xl font-bold mb-4"
      >
        Empowering Innovation
      </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.5 }}
        className="text-xl md:text-2xl"
      >
        Azure IoT, Data and Software Solutions
      </motion.p>
    </div>
  );
};

export default HeroSection;
