import React, { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import emailjs from '@emailjs/browser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';

const ContactUsPage = () => {
  const form = useRef();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formErrors, setFormErrors] = useState({});
  const [submitted, setSubmitted] = useState(false); // State to manage submission status

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData(prevFormData => ({
      ...prevFormData,
      [name]: value, // Match the input's name attribute to update the correct field
    }));
    // Optionally reset specific field error upon change
    if (formErrors[name]) {
      setFormErrors(prevErrors => ({ ...prevErrors, [name]: '' }));
    }
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = 'Email is invalid';
    }
    if (!formData.message) errors.message = 'Message is required';
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    if (Object.keys(errors).length === 0) {
      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, { publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY })
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Your message has been sent!');
          setSubmitted(true); // Update submission status
          setFormData({ name: '', email: '', message: '' }); // Clear form
        }, (err) => {
          console.log('FAILED...', err);
          alert('Failed to send the message, please try again.');
        });
    }
  };

  return (
    <div className="bg-gray-800 text-white p-12">
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="text-center mb-12"
      >
        <h2 className="text-4xl font-bold">Contact Us</h2>
        <p className="text-xl mt-4">Get in touch with our team for more information.</p>
      </motion.div>
      
      <div className="text-center mb-8">
        <p>You can contact us using the following email and phone number, or use the form below.</p>
        <div className="mt-4 flex flex-col sm:flex-row justify-center items-center gap-4">
          <div className="flex justify-center items-center gap-4">
            <FontAwesomeIcon icon={faPhone} />
            <a href="tel:+447767503366" className="text-blue-500 hover:text-blue-700">+44 7767 503366</a>
          </div>
          <div className="flex justify-center items-center gap-4">
            <FontAwesomeIcon icon={faEnvelope} />
            <a href="mailto:info@joramax.co.uk" className="text-blue-500 hover:text-blue-700">info@joramax.co.uk</a>
          </div>
        </div>
      </div>


      <motion.form
        ref={form}
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
        onSubmit={handleSubmit}
        className="max-w-md mx-auto"
      >
        <input 
          type="text" 
          name="name" 
          placeholder="Your Name" 
          className="w-full bg-gray-700 p-2 rounded mb-2" 
          value={formData.name}
          onChange={handleChange}
        />
        {formErrors.name && <p className="text-red-500">{formErrors.name}</p>}
        <input 
          type="email" 
          name="email" 
          placeholder="Your Email" 
          className="w-full bg-gray-700 p-2 rounded mb-2" 
          value={formData.email}
          onChange={handleChange}
        />
        {formErrors.email && <p className="text-red-500">{formErrors.email}</p>}
        <textarea 
          name="message" 
          placeholder="Your Message" 
          className="w-full bg-gray-700 p-2 rounded mb-2"
          value={formData.message}
          onChange={handleChange}
        ></textarea>
        {formErrors.message && <p className="text-red-500">{formErrors.message}</p>}
        <button type="submit" className="bg-blue-500 p-2 rounded">Send Message</button>
      </motion.form>
    </div>
  );
};

export default ContactUsPage;
