import React from 'react';
import HeroSection from './HeroSection';
import AboutUs from './AboutUs';
import { motion } from 'framer-motion';
import CTASection from './CTASection';

const HomePage = () => {
  return (
    <div>
      <HeroSection />

      {/* Adjusted div for responsive behavior */}
      <div className="bg-black relative mt-8 sm:mt-0">
        {/* Hide the cover image on smaller screens using Tailwind's responsive utilities */}
        <img
          src={"cover_image.png"}
          alt="Cover"
          className="hidden sm:block w-full h-auto object-cover"
          style={{ zIndex: -1 }} // Ensure the image is behind the AboutUs section
        />

        {/* Animated About Us overlay with responsive positioning and background adjustments */}
        <motion.div
          initial={{ y: 300, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: 'spring', stiffness: 100, duration: 1 }}
          className="sm:absolute inset-0 flex items-center justify-center"
          style={{ zIndex: 1 }} // Make sure this overlays the image on larger screens
        >
          <div className="bg-gray-800 bg-opacity-80 rounded-lg shadow-xl p-8 m-4 sm:m-12">
            {/* About Us content here with potential responsive text adjustments */}
            <AboutUs />
          </div>
        </motion.div>
      </div>
      <CTASection />
    </div>
  );
};

export default HomePage;
