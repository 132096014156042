import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const NavigationBar = () => {
  // Animation variants for the navigation container
  const containerVariants = {
    hidden: { 
      y: -20, 
      opacity: 0 
    },
    visible: { 
      y: 0, 
      opacity: 1,
      transition: { 
        delay: 0.2, 
        duration: 0.5 
      }
    },
  };

  return (
    <motion.nav
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="flex justify-between items-center p-4 bg-gray-800 text-white"
    >
      <Link to="/" className="text-xl font-bold">Joramax Ltd</Link>
      <div className="space-x-4">
        <Link to="/" className="hover:text-gray-300">Home</Link>
        {/* <Link to="/about" className="hover:text-gray-300">About Us</Link> */}
        <Link to="/services" className="hover:text-gray-300">Services</Link>
        <Link to="/contact" className="hover:text-gray-300">Contact Us</Link>
      </div>
    </motion.nav>
  );
};

export default NavigationBar;
