import React from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const AboutUs = () => {
  const controls = useAnimation();
  const [ref, inView] = useInView();

  // Start the animation if the component is in view
  React.useEffect(() => {
    if (inView) {
      controls.start({ scale: 1.05 });
    } else {
      controls.start({ scale: 1 });
    }
  }, [controls, inView]);

  return (
    <motion.div
      ref={ref}
      animate={controls}
      initial={{ scale: 1 }}
      whileHover={{ scale: 1.05 }}
      transition={{ type: 'spring', stiffness: 100 }}
      className="bg-white bg-opacity-90 rounded-lg shadow-xl p-8"
    >
      {/* Content of AboutUs */}
      <h2 className="text-3xl font-bold mb-4">About Joramax</h2>
      <p className="text-lg mb-4">
        Our mission is to deliver cutting-edge IoT, Data and Software Solutions within the Microsoft Azure domain. Tailored to empower businesses to achieve operational excellence and innovation. Our team of experts leverages the latest in cloud technology to build scalable, secure and effective solutions that drive growth and efficiency.
      </p>
      {/* More content */}
    </motion.div>
  );
};

export default AboutUs;
