import React, { useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloud, faCode, faDatabase, faRocket, faGlobeAmericas, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';

const ServiceCard = ({ title, description, icon, controls, custom }) => {
  const navigate = useNavigate();
  
  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: { y: 0, opacity: 1, transition: { type: "spring", stiffness: 100, damping: 20, duration: 0.5 } },
    hover: { scale: 1.05 },
  };

  const handleClick = () => {
    navigate('/contact');
  };

  return (
    <motion.div
      variants={cardVariants}
      initial="hidden"
      animate={controls}
      custom={custom} // Use the custom prop for individual delays
      whileHover="hover"
      onClick={handleClick}
      className="bg-gray-700 p-6 rounded-lg text-center cursor-pointer"
    >
      <FontAwesomeIcon icon={icon} size="3x" className="text-white mb-4" />
      <h3 className="text-2xl font-bold">{title}</h3>
      <p className="mt-2">{description}</p>
    </motion.div>
  );
};

const ServicesPage = () => {
  const controls = useAnimation();
  const services = [
    {
      title: 'Azure IoT Platform',
      description: 'Leverage Azure\'s IoT resources including IoT Hub, Event Grid, Azure Data Explorer and more. Connect and manage your IoT devices efficiently using a variety of protocols. Ensure real-time responsiveness and interconnectivity.',
      icon: faCloud,
      delay: 0.2
    },
    {
      title: 'Cloud Native Application Development',
      description: 'Dynamic, scalable web apps customised for cloud operation. Enhanced deployment flexibility and environment consistency.',
      icon: faCode,
      delay: 0.4
    },
    {
      title: 'API Integration',
      description: 'Integrate and automate your business processes with custom API solutions. Connect internal and external applications to improve workflow.',
      icon: faExchangeAlt,
      delay: 0.6
    },
    {
      title: 'Azure Data Solutions',
      description: 'Harness Azure\'s data platform including SQL, NoSQL, Data Lake, Data Factory, Synapse Analytics, and Databricks. Unlock actionable insights and drive strategic decision-making.',
      icon: faDatabase,
      delay: 0.8
    },
    {
      title: 'Digital Cloud Migration',
      description: 'Navigate your digital transformation journey with our expert guidance. Transition smoothly to the cloud, ensuring minimal disruption and maximizing business value.',
      icon: faRocket,
      delay: 1.0
    },
    {
      title: 'Other Cloud Platforms',
      description: 'Beyond Azure, our expertise extends to other platforms including AWS and Google Cloud. Offering a broad spectrum of cloud services to meet your specific needs and ensure a multi-cloud strategy that fosters resilience and flexibility.',
      icon: faGlobeAmericas,
      delay: 1.2
    },
  ];

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.2, type: "spring", stiffness: 100, damping: 20, duration: 0.5 }
    }));
  }, [controls]);

  return (
    <div className="bg-gray-800 text-white p-12">
      <motion.div
        initial="hidden"
        animate="visible"
        variants={{
          hidden: { opacity: 0, x: -100 },
          visible: { opacity: 1, x: 0, transition: { delay: 0.2, duration: 0.5 } }
        }}
        className="text-center mb-12"
      >
        <h2 className="text-4xl font-bold">Our Expertise</h2>
        <p className="text-xl mt-4">Delivering cutting-edge Azure and Cloud solutions tailored for your business.</p>
      </motion.div>
      
      <div className="grid md:grid-cols-2 gap-8">
        {services.map((service, index) => (
          <ServiceCard key={index} {...service} controls={controls} custom={index} />
        ))}
      </div>
    </div>
  );
};

export default ServicesPage;