import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './components/main/HomePage';
import ServicesPage from './components/services/ServicesPage';
import ContactUsPage from './components/contact/ContactUsPage';
import NavigationBar from './components/navigation/NavigationBar';
import Footer from './components/navigation/Footer';
import './App.css';

const App = () => {
  return (
    <div className="bg-grey-800">
      <Router>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/contact" element={<ContactUsPage />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
};

export default App;
